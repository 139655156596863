import { template as template_ac4574880de3433fa997728cf9d2c112 } from "@ember/template-compiler";
const FKText = template_ac4574880de3433fa997728cf9d2c112(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
