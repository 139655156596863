import { template as template_3b689598e13d44adad23ecb33577a008 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3b689598e13d44adad23ecb33577a008(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
