import { template as template_ac18422212f34b9ea9d7038c4ab172d8 } from "@ember/template-compiler";
const FKLabel = template_ac18422212f34b9ea9d7038c4ab172d8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
