import { template as template_7274ff09438a4d93b907c8f267c2f955 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_7274ff09438a4d93b907c8f267c2f955(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
